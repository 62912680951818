import { graphql } from 'gatsby';

import ErrorNotFound from '~/components/Error/NotFound';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';

const ErrorNotFoundPage = ({ data: { page } }) => (
  <Layout transparentNav={page.transparentNav} navColor={page.navColor}>
    <Metadata />
    <ErrorNotFound page={page} />
  </Layout>
);

export default ErrorNotFoundPage;

export const query = graphql`
  query ErrorNotFoundPage($locale: String) {
    page: contentfulPage(slug: { eq: "404" }, node_locale: { eq: $locale }) {
      ...ContentPageFragment
    }
  }
`;
